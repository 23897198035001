import { Typography } from '@material-ui/core';
import * as React from 'react';

const DreamTeam = () => {
  return (
    <React.Fragment>
      <DreamTeamContent
        title="Admin Team"
        description="Our team uses our gift of administration to support Lifehouse teams with any administrative needs by using computer skills, data entry, and attention to details and various administrative functions, including Sunday offerings and team management."
        requirements="Age: 16+, Background check"
      />
      <DreamTeamContent
        title="Baptism Team"
        description="Our team prepares for and hosts the Lifehouse baptism experience."
      />
      <DreamTeamContent
        title="Host/Greeter Team"
        description="Our team greets and welcomes everyone and brings a personal touch to our worship experiences by hosting in the foyer and in the auditorium. Helping people to their seats and help maintain a distraction free Service environment."
      />
      <DreamTeamContent
        title="Facilities Team"
        description="Our team works behind the scenes to maintain and support every area of our church, with upkeep and maintenance as well as any upgrades that need to be done."
      />
      <DreamTeamContent
        title="Outreach Team"
        description="Our team facilitates serving opportunities through various outreach expressions throughout the year."
        requirements="Background check"
      />
      <DreamTeamContent
        title="Events/Growth Track Team"
        description="Our team serves at Growth Track, preparing the venue, greeting guests and providing materials and assistance to those discovering their next steps at Lifehouse, serving at conferences and events by setting up decorations and facilitating meals and snacks."
      />
      <DreamTeamContent
        title="Service Team"
        description="Our team serves in our facilities ie; kitchen with our coffee shop and coffee stations facilitating snacks and drinks before and after services and events. Making sure the auditorium, bathrooms and classrooms are clean and presentable. Packing chairs, opening and closing windows, preparing for the Service, as well as leaving everything in good order after every service."
      />
      <DreamTeamContent
        title="First Aid"
        description="Our team of health care professionals serves by providing basic medical care in the event of an accident or emergency."
        requirements="Medical certification, Background check"
      />
      <DreamTeamContent
        title="Lifehouse Kids Team"
        description="Our team invests in the lives of primary school children through worship, Biblical teaching, videos, Small Groups, and games."
        requirements="Background check"
      />
      <DreamTeamContent
        title="Lifehouse Youth"
        description="Our team helps high school students get connected to Gods Kingdom and find their purpose through Youth nights, Small Groups, camps, etc."
        requirements="Background check"
      />
      <DreamTeamContent
        title="Worship Team"
        description="Our team facilitates a powerful worship experience through vocals, instruments, in every Service or event."
        requirements="Worship training process, Background check"
      />
      <DreamTeamContent
        title="Legacy Team"
        description="Our team uses our gift of giving to support major projects and facilitate the overall vision of the church through strategic financial offerings as need arises."
      />
      <DreamTeamContent
        title="Nursery/Preschool Team"
        description="Our team ministers to toddlers through Bible stories, hands-on activities, and personal interactions."
        requirements="Background check"
      />
      <DreamTeamContent
        title="Online Campus Team"
        description="Our team works together to build and facilitate our online campus experience, running either live or pre-recorded production."
      />
      <DreamTeamContent
        title="Social Media/Marketing Team"
        description="Our team works together to build and facilitate a clear representation of our church and ministries on our social media platforms to impact and influence our community."
      />
      <DreamTeamContent
        title="Parking Team"
        description="Our team serves as the first impression for those coming to Lifehouse Church by welcoming people on and off the property and by providing a safe and efficient parking experience."
        requirements="Age: 15+"
      />
      <DreamTeamContent
        title="Prayer Team"
        description="Our team provides intercessory prayer covering for services, teams, and ministries at Lifehouse Church. A pivotal ministry of intersession that covers the church and prayer requests."
      />
      <DreamTeamContent
        title="Care Team"
        description="Our team provides practical care and hands on service for various needs of people within the church and community ie: Food parcels, clothing, hospital visit’s, any felt need."
      />
      <DreamTeamContent
        title="Production/Sound Team"
        description="Our team assists in the production of the overall worship experience Including sound, multimedia cameras, and other visual elements including online streaming and media developments."
      />
      <DreamTeamContent
        title="Small Group Leaders"
        description="We are trained to facilitate Small Groups and equipped to build relationships while helping group members discover their next steps."
        requirements="Background check"
      />
    </React.Fragment>
  );
};

interface Props {
  title: string;
  description: string;
  requirements?: string;
}

const DreamTeamContent = ({ title, description, requirements }: Props) => {
  return (
    <div style={{ marginBottom: 24 }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography gutterBottom>{description}</Typography>
      {requirements && (
        <Typography style={{ fontWeight: 500, fontSize: '11pt' }}>
          {requirements}
        </Typography>
      )}
    </div>
  );
};

export default DreamTeam;
