import * as React from 'react';
import Layout from '../layouts/Layout';

import { DreamTeam } from '../components/DreamTeam';
import { Container } from '@material-ui/core';

const DreamTeamPage = () => {
  return (
    <Layout title="Dream Team">
      <div style={{ marginTop: 80 }} />
      <Container
        style={{ padding: '32px 8px 64px 8px', minHeight: 480 }}
        maxWidth="md"
      >
        <DreamTeam />
      </Container>
    </Layout>
  );
};

export default DreamTeamPage;
